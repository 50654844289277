.Contact {
  position: absolute;
  background: color('orange');
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 100;
  display: none;

  &--active {
    .Contact__email__link--1 {
      opacity: 1 !important;
      transform: translate(0rem, .5rem) !important;
      transition: transform 1s 2.5s, opacity 1s 2.5s;
    }

    .Contact__email__link--2 {
      opacity: 1 !important;
    }

    .Contact__email__link--3 {
      opacity: 1 !important;
      transform: translate(0rem, 0rem) !important;
      transition: transform 1s 2.5s, opacity 1s 2.5s;
    }
  }

  &__email {
    position: absolute;
    bottom: $global-padding-mobile;
    left: $global-padding-mobile;

    @media (min-width: $mobile-breakpoint) {
      bottom: $global-padding-desktop;
      left: $global-padding-desktop;
    }

    &__link {
      display: flex;
      flex-wrap: wrap;
      cursor: pointer;

      &--1 {
        width: 100%;
        opacity: 0;
        transform: translate(-.25rem, .5rem);
        transition: transform 1s, opacity 1s;
      }

      &--3 {
        transform: translate(0rem, .25rem);
        opacity: 0;
        transition: transform 1s, opacity 1s;
      }
    }
  }

  &__title {
    display: none;
    position: absolute;
    top: 50%;
    left: $global-padding-mobile;
    transform: translateY(-50%);
    opacity: 0;
    animation: fade-in 2s 1s forwards;

    @media (min-width: $mobile-breakpoint) {
      display: block;
      top: $global-padding-desktop;
      right: $global-padding-desktop;
      left: auto;
      transform: rotate(90deg) translate(100%, 50%);
      transform-origin: center right;
    }
  }

  &__logo {
    background: none;
    position: absolute;
    cursor: pointer;
    top: $global-padding-mobile;
    left: $global-padding-mobile;

    @media (min-width: $mobile-breakpoint) {
      top: $global-padding-desktop;
      left: $global-padding-desktop;
    }
  }

  &__background {
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;

    &__color {
      position: absolute;
      transition: margin 1s;
    }
  }
}
