.Homepage {
  background: color('white');
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 1.5s;
  z-index: 1;

  &__site-title {
    position: absolute;
    bottom: $global-padding-mobile;
    left: $global-padding-mobile;
    opacity: 0;
    animation: fade-in 2s forwards;

    @media (min-width: $mobile-breakpoint) {
      bottom: $global-padding-desktop;
      left: $global-padding-desktop;
    }
  }

  &__description {
    width: 100%;
    max-width: 50rem;
    padding: $global-padding-mobile;
    opacity: 0;
    animation: fade-in 2s 1s forwards;
  }
}
