$radius: 1rem;

.Logo {
  z-index: 200;
  background: none;
  position: absolute;
  cursor: pointer;
  padding: 0;
  opacity: 0;
  top: $global-padding-mobile;
  left: $global-padding-mobile;
  animation: fade-in 2s forwards;

  @media (min-width: $mobile-breakpoint) {
    top: $global-padding-desktop;
    left: $global-padding-desktop;
  }

  .logo-part {
    background: color('black');
    position: relative;
    overflow: hidden;
    transform: translateX(0);

    &::after {
      content: '';
      position: absolute;
      top: -70%;
      left: -60%;
      transform: rotate(30deg);
      height: 180%;
      width: 180%;
      filter: blur(6px);
      transition: all 1s, transform .5s;
      background: linear-gradient(
        to bottom,
        color('tabriz-blue') 0%,
        color('vellum') 60%,
        color('factory-yellow') 100%
      );
    }
  }

  .logo-part:nth-child(2) {
    &::after {
      content: '';
      position: absolute;
      top: -20%;
      left: -10%;
      transform: rotate(10deg);
      height: 180%;
      width: 180%;
      filter: blur(5px);
      transition: all 1s, transform .5s;
      background: linear-gradient(
        to bottom,
        color('factory-yellow') 0%,
        color('turquoise') 85%
      );
    }
  }

  .logo-part:nth-child(3) {
    background: color('red');

    &::after {
      content: '';
      position: absolute;
      top: -65%;
      left: -50%;
      transform: rotate(50deg);
      height: 180%;
      width: 180%;
      filter: blur(6px);
      transition: all 1s, transform .5s;
      background: linear-gradient(
        to bottom,
        color('factory-yellow') 0%,
        color('orange') 95%,
      );
    }
  }
}

.logo-wrapper {
  display: flex;

  @media (min-width: $mobile-breakpoint) {
    &:hover {
      .logo-part {
        transform: translateX(0);

        &::after {
          left: -70%;
        }
      }

      .logo-part:nth-child(2) {
        transform: translateX(.25rem);

        &::after {
          left: -50%;
        }
      }
      .logo-part:nth-child(3) {
        transform: translateX(.5rem);

        &::after {
          left: -90%;
        }
      }
    }
  }
}

.logo-part {
  width: 1.25rem;
  height: 2.5rem;
  background: black;
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
  transition: all 1s, transform .5s;

  &.logo-part-reverse {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
  }
}

.Logo--active {
  .logo-part,
  .logo-part:nth-child(2),
  .logo-part:nth-child(3) {
    background: color('black');

    &::after {
      opacity: 0;
    }
  }
}
