@import './reset.scss';
@import './colors.scss';
@import './variables.scss';
@import './logo.scss';
@import './background.scss';

@font-face {
  font-family: 'Plaak';
  src: url('./../assets/plaak-dev.otf') format('opentype');
}

[data-whatintent='mouse'] *:focus {
  outline: none;
}

html,
body {
  font-size: 16px;
  font-family: 'Plaak', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, sans-serif;
}

a {
  color: black;
  text-decoration: none;
}

.text-lg {
  font-size: 1.25rem;
  line-height: 1.875rem;

  @media (min-width: $mobile-breakpoint) {
    font-size: 2rem;
    line-height: 2.625rem;
  }
}

.text-sm {
  font-size: 1.25rem;
  line-height: 1.875rem;
}

@keyframes fade-in {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

@import './components/Homepage.scss';
@import './components/Contact.scss';
