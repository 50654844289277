.bgc1 {
  width: 45%;
  left: -20%;

  @media (min-width: $mobile-breakpoint) {
    width: 25%;
    left: -10%;
  }

  height: 50vh;
  background: color('red');
  top: 10%;
  filter: blur(50px);
  z-index: 100;
  border-bottom-right-radius: 50%;

  transform: translate3d(-35%, -26%, 0) rotate(7deg);
  animation: move-bgc1 6s 1s ease-in-out infinite;
  @keyframes move-bgc1 {
    0% { transform: translate3d(-35%, -26%, 0) rotate(7deg) }
    50% { transform: translate3d(0, 0, 0) rotate(0) }
    100% { transform: translate3d(-35%, -26%, 0) rotate(7deg) }
  }
}

.bgc2 {
  width: 30%;
  height: 100vh;
  background: color('orange');
  top: 0;
  left: -10%;
  filter: blur(50px);
  z-index: 50;

  transform: translate3d(16%, 24%, 0) rotate(15deg);
  animation: move-bgc2 6s 1s ease-in-out infinite;
  @keyframes move-bgc2 {
    0% { transform: translate3d(16%, 24%, 0) rotate(15deg) }
    50% { transform: translate3d(0, 0, 0) rotate(0) }
    100% { transform: translate3d(16%, 24%, 0) rotate(15deg) }
  }
}

.bgc3 {
  width: 80%;
  top: 20%;
  left: 10%;
  height: 60vh;

  @media (min-width: $mobile-breakpoint) {
    width: 30%;
    top: 0;
    left: 0;
    height: 100vh;
  }
  
  background: color('factory-yellow');
  filter: blur(50px);
  z-index: 30;
  border-bottom-right-radius: 50%;;

  transform: translate3d(-34%, 41%, 0) rotate(7deg);
  animation: move-bgc3 9s 1s ease-in-out infinite;
  @keyframes move-bgc3 {
    0% { transform: translate3d(-34%, 41%, 0) rotate(7deg) }
    50% { transform: translate3d(0, 0, 0) rotate(20deg) }
    100% { transform: translate3d(-34%, 41%, 0) rotate(7deg) }
  }
}

.bgc4 {
  display: none;

  @media (min-width: $mobile-breakpoint) {
    display: block;
  }

  width: 40%;
  height: 50vh;
  background: color('turquoise');
  top: 50%;
  left: -5%;
  filter: blur(50px);
  z-index: 20;

  transform: translate3d(8%, 43%, 0) rotate(-9deg);
  animation: move-bgc4 8s 1s ease-in-out infinite;
  @keyframes move-bgc4 {
    0% { transform: translate3d(8%, 43%, 0) rotate(-9deg) }
    50% { transform: translate3d(0, 0, 0) rotate(-20deg) }
    100% { transform: translate3d(8%, 43%, 0) rotate(-9deg) }
  }
}

.bgc5 {
  display: none;

  @media (min-width: $mobile-breakpoint) {
    display: block;
  }

  width: 40%;
  height: 10vh;
  background: color('black');
  top: 90%;
  left: 5%;
  filter: blur(60px);
  z-index: 20;

  transform: translate3d(12%, 19%, 0) rotate(8deg);
  animation: move-bgc5 6s 1s ease-in-out infinite;
  @keyframes move-bgc5 {
    0% { transform: translate3d(12%, 19%, 0) rotate(8deg) }
    50% { transform: translate3d(0, 0, 0) rotate(20deg) }
    100% { transform: translate3d(12%, 19%, 0) rotate(8deg) }
  }
}

.bgc6 {
  display: none;

  @media (min-width: $mobile-breakpoint) {
    display: block;
  }

  width: 20%;
  height: 10vh;
  background: color('red');
  top: 90%;
  left: 35%;
  filter: blur(60px);
  z-index: 20;

  transform: translate3d(8%, 15%, 0) rotate(4deg);
  animation: move-bgc6 7s 1s ease-in-out infinite;
  @keyframes move-bgc6 {
    0% { transform: translate3d(8%, 15%, 0) rotate(4deg) }
    50% { transform: translate3d(0, 0, 0) rotate(20deg) }
    100% { transform: translate3d(8%, 15%, 0) rotate(4deg) }
  }
}

.bgc7 {
  display: none;

  @media (min-width: $mobile-breakpoint) {
    display: block;
  }

  width: 30%;
  height: 120vh;
  background: color('vellum');
  top: -15%;
  right: -5%;
  filter: blur(80px);
  z-index: 20;

  transform: translate3d(14%, -34%, 0) rotate(6deg);
  animation: move-bgc7 9s 1s ease-in-out infinite;
  @keyframes move-bgc7{
    0% { transform: translate3d(14%, -34%, 0) rotate(6deg) }
    50% { transform: translate3d(0, 0, 0) rotate(20deg) }
    100% { transform: translate3d(14%, -34%, 0) rotate(6deg) }
  }
}

.bgc8 {
  display: none;

  @media (min-width: $mobile-breakpoint) {
    display: block;
  }

  width: 40%;
  height: 100vh;
  background: color('pistachio');
  top: -50%;
  right: 20%;
  filter: blur(70px);
  z-index: 20;
  border-bottom-left-radius: 40%;
  border-top-left-radius: 40%;

  transform: translate3d(12%, 17%, 0) rotate(-42deg);
  animation: move-bgc8 8s 1s ease-in-out infinite;
  @keyframes move-bgc8 {
    0% { transform: translate3d(12%, 17%, 0) rotate(-42deg) }
    50% { transform: rotate(-13deg); }
    100% { transform: translate3d(12%, 17%, 0) rotate(-42deg) }
  }
}

.bgc9 {
  bottom: -58%;
  right: -20%;

  @media (min-width: $mobile-breakpoint) {
    width: 20%;
    height: 60vh;
    bottom: -20%;
    right: -15%;
    filter: blur(100px);
    background: color('tabriz-blue');
  }

  width: 50%;
  height: 70vh;
  background: color('turquoise');
  filter: blur(50px);
  z-index: 60;
  border-radius: 15%;

  transform: translate3d(-12%, 9%, 0) rotate(-25deg);
  animation: move-bgc9 9s 1s ease-in-out infinite;
  @keyframes move-bgc9 {
    0% { transform: translate3d(-12%, 9%, 0) rotate(-25deg) }
    50% { transform: rotate(-10deg) }
    100% { transform: translate3d(-12%, 9%, 0) rotate(-25deg) }
  }
}
